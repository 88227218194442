window.components.mapLocation = ({ mapPath }) => {
  return {
    mapUrl: new URL(location.origin + mapPath),
    loading: false,
    onClick() {
      if (!this.loading) {
        this.loading = true
        this.retrievePosition()
      }
    },
    retrievePosition() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => this.onSuccess(position),
          () => this.onFailure(),
          { timeout: 10000 }
        )
      }
      else {
        this.onFailure()
      }
    },
    onSuccess(position) {
      this.mapRedirect(this.mapBounds(position))
    },
    onFailure() {
      this.mapRedirect()
    },
    mapBounds(position) {
      // calculating approximately 100km map bounds around the center
      const lat_change = 0.5
      const lon_change = Math.abs(Math.cos(position.coords.latitude * (Math.PI/180)))

      const sw_lat = position.coords.latitude - lat_change
      const sw_lon = position.coords.longitude - lon_change
      const ne_lat = position.coords.latitude + lat_change
      const ne_lon = position.coords.longitude + lon_change

      return [sw_lat, sw_lon, ne_lat, ne_lon]
    },
    mapRedirect(mapBounds) {
      if (mapBounds) {
        this.mapUrl.searchParams.append('filters[map_bounds]', mapBounds)
      }
      window.location.replace(this.mapUrl)
    }
  }
}
