import enhancedFetch from 'compact/shared/general/enhanced_fetch.js'

window.components.pages.firstSavedSearchBanner = ({
  bannerClosedPath
}) => {
  return {
    bannerClosedPath: bannerClosedPath,
    isOpen: true,
    permanentlyClosed: false,
    close(permanently = false) {
      this.isOpen = false
      this.$nextTick(() => window.components.modal().dispatch('search-modal-realign'))

      if (!permanently) return

      this.permanentlyClosed = true
      enhancedFetch(this.bannerClosedPath, { method: 'POST' })
    },
    open() {
      if (this.permanentlyClosed) return

      this.isOpen = true
      this.$nextTick(() => window.components.modal().dispatch('search-modal-realign'))
    }
  }
}
