import Swiper from 'swiper' // eslint-disable-line

window.components.projects.mainCarousel = ({ projectsAmount }) => {
  return {
    projectsAmount: projectsAmount,
    currentSlideIndex: 0,
    swiper: null,
    init() {
      this.swiper = new Swiper(this.$refs.swiper, {
        loop: false,
        slidesPerView: 1.1,
        spaceBetween: 12,
        centeredSlides: false
      })
      const _this = this
      this.swiper.on('slideChange', function (object) {
        this.currentSlideIndex = object.activeIndex
      })
    },
    nextSlide() {
      if (this.currentSlideIndex + 1 < this.projectsAmount) {
        this.currentSlideIndex += 1
      } else {
        this.currentSlideIndex = 0
      }
      this.$refs.projectsList.style.transform = `translateX(-${this.currentSlideIndex * 100}%)`
    }
  }
}
